import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { IconButton, Link } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import TableHeader from "../../../components/TableHeader/TableHeader"
import EjercicioSelector from "../../../components/form/EjercicioSelector/EjercicioSelector"
import InformationTable from "../../../components/Table/InformationTable"
import { textFilter } from "../../../utils/table"
import { getFormacionesYear } from "../../../utils/api/formaciones"
import { downloadCSVFile, getCSVContent } from "../../../utils/csv"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import css from "./Formaciones.module.css"

const Formaciones = () => {
  const notification = useNotification()

  const [search, setSearch] = useState("")
  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())

  const { isLoading, data: formacionList = [] } = useQuery({
    queryKey: ["formacion", "formacion", "list", "year", ejercicio],
    enabled: !!ejercicio,
    queryFn: () => getFormacionesYear(ejercicio)
      .then((datos) => datos)
      .catch((err) => {
        notification.error({ title: "Error al recuperar las formaciones", content: err })
        return []
      }),
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: ["Nombre", "E-mail", "centro"],
      data: formacionList,
      transform: (c) => [c.nombre, c.email, c.centro],
    })
    downloadCSVFile(content, "formaciones")
  }

  const filterSearch = (alumno) => {
    return textFilter({
      object: alumno,
      fields: ["nombre", "email"],
      search: search,
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <IconButton
              className={css.download}
              size="small"
              color="primary"
              onClick={handleExport}
            >
              <i className="material-icons">download</i>
            </IconButton>
            <EjercicioSelector
              className={css.ejercicio}
              name="ejercicio"
              label="Año"
              value={ejercicio}
              minYear={2024}
              onChange={e => setEjercicio(e.target.value)}
            />
          </div>
        }
        title={`${formacionList.length} formaciones de ${ejercicio}`}
        search={search}
        onSearchChange={(e) => setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: "Nombre", key: "nombre" },
          { title: "E-mail", key: "email" },
          { title: "Centro", key: "centro" },
        ]}
        data={formacionList.filter(filterSearch).map((formacion) => ({
          nombre: (
            <Link
              className={css.link}
              href={`/formaciones/${formacion.id}/ficha`}
            >
              {formacion.nombre}
            </Link>
          ),
          email: formacion.email || "--",
          centro: formacion.centro || "--",
        }))}
      />
    </PageLoading>
  )
}

export default Formaciones
