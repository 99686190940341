import { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import Content from '../Content/Content'
import Help from '../Help/Help'

import Dashboard from '../../views/Dashboard/Dashboard'
import Calendario from '../../views/Calendario/Calendario'
import CRM from '../../views/CRM/CRM'
import Presupuestador from '../../views/Presupuestador/Presupuestador'
import Planificacion from '../../views/Planificacion/Planificacion'
import TerminalPagos from '../../views/TerminalPagos/TerminalPagos'
import Contabilidad from '../../views/Contabilidad/Contabilidad'
import Profesores from '../../views/Profesores/Profesores'
import Perfil from '../../views/Perfil/Perfil'

import NuevoLead from '../../views/NuevoLead/NuevoLead'
import NuevoAlumno from '../../views/NuevoAlumno/NuevoAlumno'

import HistoricoLeads from '../../views/Historico/Leads/Leads'
import HistoricoAlumnos from '../../views/Historico/Alumnos/Alumnos'
import HistoricoContrataciones from '../../views/Historico/Contrataciones/Contrataciones'
import HistoricoServicios from '../../views/Historico/Servicios/Servicios'
import HistoricoFormaciones from "../../views/Historico/Formaciones/Formaciones"

import SeguimientoAsistencias from '../../views/Seguimiento/Asistencias/Asistencias'
import SeguimientoContabilidad from '../../views/Seguimiento/Contabilidad/Contabilidad'
import SeguimientoProfesores from '../../views/Seguimiento/Profesores/Profesores'
import SeguimientoPruebas from '../../views/Seguimiento/Pruebas/Pruebas'

import AlumnoAsistencias from '../../views/Alumno/Asistencias/Asistencias'
import AlumnoContabilidad from '../../views/Alumno/Contabilidad/Contabilidad'
import AlumnoFicha from '../../views/Alumno/Ficha/Ficha'
import AlumnoFoto from '../../views/Alumno/Foto/Foto'
import AlumnoHistorico from '../../views/Alumno/Historico/Historico'
import AlumnoNotas from '../../views/Alumno/Notas/Notas'
import AlumnoClassfy from '../../views/Alumno/Classfy/Classfy'
import AlumnoGaleria from '../../views/Alumno/Galeria/Galeria'
import AlumnoCuadernillos from '../../views/Alumno/Cuadernillos/Cuadernillos'
import Alumnos from '../../views/Alumnos/Alumnos'

import LeadFicha from '../../views/Lead/Ficha/Ficha'
import LeadContactos from '../../views/Lead/Contactos/Contactos'
import LeadClassfy from '../../views/Lead/Classfy/Classfy'
import LeadGaleria from '../../views/Lead/Galeria/Galeria'

import Formaciones from "../../views/Formaciones/Formaciones"
import FormacionFicha from "../../views/Formacion/Ficha/Ficha"
import FormacionAsistencias from "../../views/Formacion/Asistencias/Asistencias"
import FormacionClassfy from "../../views/Formacion/Classfy/Classfy"
import FormacionGaleria from "../../views/Formacion/Galeria/Galeria"
import FormacionCuadernillos from "../../views/Formacion/Cuadernillos/Cuadernillos"

import useTitle from '../../hooks/useTitle'

const Layout = () => {

  useTitle()

  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={() => setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/calendario' element={<Calendario />} />
          <Route path='/nuevo-lead' element={<NuevoLead />} />
          <Route path='/crm' element={<CRM />} />
          <Route path='/presupuestador' element={<Presupuestador />} />
          <Route path='/nuevo-alumno' element={<NuevoAlumno />} />
          <Route path='/planificacion' element={<Planificacion />} />
          <Route path='/terminal-pagos' element={<TerminalPagos />} />
          <Route path='/contabilidad' element={<Contabilidad />} />

          <Route path='/perfil' element={<Perfil />} />

          <Route path='/profesores' element={<Profesores />} />

          <Route path='/historico/leads' element={<HistoricoLeads />} />
          <Route path='/historico/alumnos' element={<HistoricoAlumnos />} />
          <Route path='/historico/contrataciones' element={<HistoricoContrataciones />} />
          <Route path='/historico/servicios' element={<HistoricoServicios />} />
          <Route path='/historico/formaciones' element={<HistoricoFormaciones/>} />

          <Route path='/seguimiento/asistencias' element={<SeguimientoAsistencias />} />
          <Route path='/seguimiento/contabilidad' element={<SeguimientoContabilidad />} />
          <Route path='/seguimiento/profesores' element={<SeguimientoProfesores />} />
          <Route path='/seguimiento/pruebas' element={<SeguimientoPruebas/>} />


          <Route path='/alumnos/:id/asistencias' element={<AlumnoAsistencias />} />
          <Route path='/alumnos/:id/contabilidad' element={<AlumnoContabilidad />} />
          <Route path='/alumnos/:id/ficha' element={<AlumnoFicha />} />
          <Route path='/alumnos/:id/foto' element={<AlumnoFoto />} />
          <Route path='/alumnos/:id/historico' element={<AlumnoHistorico />} />
          <Route path='/alumnos/:id/notas' element={<AlumnoNotas />} />
          <Route path='/alumnos/:id/classfy' element={<AlumnoClassfy />} />
          <Route path='/alumnos/:id/galeria' element={<AlumnoGaleria />}/>
          <Route path='/alumnos/:id/cuadernillos' element={<AlumnoCuadernillos />}/>
          <Route path='/alumnos' element={<Alumnos />} />

          <Route path='/leads/:id/ficha' element={<LeadFicha />} />
          <Route path='/leads/:id/contactos' element={<LeadContactos />} />
          <Route path='/leads/:id/classfy' element={<LeadClassfy />} />
          <Route path='/leads/:id/galeria' element={<LeadGaleria />}/>

          <Route path='/formaciones' element={<Formaciones />}/>
          <Route path='/formaciones/:id/ficha' element={<FormacionFicha />}/>
          <Route path='/formaciones/:id/asistencias' element={<FormacionAsistencias/>}/>
          <Route path='/formaciones/:id/classfy' element={<FormacionClassfy/>}/>
          <Route path='/formaciones/:id/galeria' element={<FormacionGaleria/>}/>
          <Route path='/formaciones/:id/cuadernillos' element={<FormacionCuadernillos/>}/>
        </Routes>
      </Content>

      <Help open={help} onClose={() => setHelp(false)} />
    </>
  )
}

export default Layout
