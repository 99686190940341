import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './CausaPerdidoSelector.module.css'

const CAUSAS = [
  { 
    value: 'No podemos contactar con él', 
    text: 'No podemos contactar con él' 
  },
  {
    value: 'No le interesa', 
    text: 'No dice porqué' 
  },
  { 
    value: 'No le interesa porque es caro', 
    text: 'Es caro' 
  },
  {
    value: 'No le interesa porque ha encontrado una más barata',
    text: 'Ha encontrado una más barata'
  },
  {
    value: 'No le interesa porque pensaba que eran clases particulares',
    text: 'Pensaba que eran clases particulares'
  },
  {
    value: 'No le interesa porque ha encontrado una más cercana',
    text: 'Ha encontrado una más cercana'
  },
  {
    value: 'No le interesa porque está lejos de su domicilio',
    text: 'Está lejos de su domicilio'
  },
  {
    value: 'No le interesa porque no le convence el método',
    text: 'No le convence el método'
  },
  {
    value: 'No le interesa porque la prueba no le ha gustado',
    text: 'La prueba no le ha gustado'
  },
  {
    value: 'No le interesa porque no es lo que esperaba',
    text: 'No es lo que esperaba'
  },
  {
    value: 'No le interesa porque los profesores no le han gustado',
    text: 'Los profesores no le han gustado'
  },
  {
    value: 'No le interesa porque la atención no ha sido buena',
    text: 'La atención no ha sido buena'
  },
  {
    value: 'No le podemos dar el servicio',
    text: 'No le podemos dar servicio'
  },
  { 
    value: 'No le interesa por otros motivos', 
    text: 'Otra' 
  },
]

const CausaPerdidoSelector = ({ className, name, label, value, onChange })=> (
  <FormControl 
    className={`${className} ${css.main}`} 
    variant='standard'
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {CAUSAS.map(({ value, text }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default CausaPerdidoSelector
